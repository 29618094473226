import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';

import { PerfilService } from './../../_services/perfil.service';
import { MessageService } from './../../_services/message.service';

import { Perfil } from './../../_models/perfil';

import { MessageComponent } from './../../shared/message/message.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(
    private _msgService: MessageService,
    private _perfilService: PerfilService) {

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  public lista: Perfil[];
  public loading: boolean = false;

  ngOnInit() {
    this.ObterLista();
    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
      this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.PerfisDeAcesso, Operacao.Alteracao);
  }

  private ObterLista() {
    this.loading = true;
    this._perfilService.getPerfis()
      .subscribe(lista => {
        this.lista = lista;
        this.loading = false;
      });
  }

  private DeletarPerfil(id: number) {
    swal({
      title: 'Confirmação',
      text: 'Deseja excluir o Perfil?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(success => {

      this.loading = true;
      this._perfilService.deletarPerfil(id)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this._msgService.addMessage('Perfil de Acesso', result.Message, "success");
            this.ObterLista();
          }
          else {
            var msgErr: string[] = [];
            result.Erros.forEach(element => msgErr.push(element.Message));
            this._msgService.addMessageArray("Perfil de Acesso", msgErr, "error", LogFrontFuncionalidade.PerfisDeAcesso, Operacao.Exclusao, id);
          }
        });

    }, dismiss => {
    });
  }

}
