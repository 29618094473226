export class PropostaComercialRequest {
    idProposta: number = 0;
    idTrecho: number = 0;
    flag_ativa: string = '';
    numProposta: number = 0;
    idMunicOrigem: number;      // Campo de filtro pode ser nulo na requisição
    idMunicDestino: number = 0;
    idPortolOrigem: number;     // Campo de filtro pode ser nulo na requisição
    idPortoDestino: number;     // Campo de filtro pode ser nulo na requisição
    idCategoriaContainer: number = 0;
    tamanhoContainer: number = 0;
    qtdeContainer: number = 0;
    idFamiliaProduto: number = 0;
    idUsuario: number = 0;
    tipoOperacaoComercial: string = '';
    cnpjEmb: string = '';
    ieEmb: string = '';
    modal: string = '';
    cnpjCli: string ='';
    ieCli: string = '';
}