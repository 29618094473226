import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProgramacaoNaviosComponent } from './programacao-navios.component';

const programacaoNaviosRoutes: Routes = [
    { path: '', component: ProgramacaoNaviosComponent }
];

@NgModule({
    imports: [RouterModule.forChild(programacaoNaviosRoutes)],
    exports: [RouterModule]
})
export class ProgramacaoNaviosRoutingModule {}