import { Assunto } from './assunto';
export class FaleConosco{
    FaleConoscoID: number;
    Nome: string;
    Email: string;
    Data: Date;
    strData: string;
    Telefone: string;
    Mensagem: string;
    Assunto: Assunto;
}