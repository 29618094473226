
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';


import { BaseService } from './base.service';
import { ValidationResult } from './../_models/validation-result';
import { UF } from './../_models/uf';
import { Periodicidade } from './../_models/periodicidade';
import { ResponsavelOva } from './../_models/responsavelOva';
import { ResponsavelDesova } from './../_models/responsavelDesova';
import { TipoEmbalagem } from './../_models/tipoEmbalagem';
import { VeiculoEspecial } from './../_models/veiculoEspecial';
import { TipoPagadorFrete } from './../_models/tipoPagadorFrete';
import { TipoOperacaoComercial } from './../_models/tipoOperacaoComercial';
import { Incidente } from 'app/_models/incidente';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private _baseService: BaseService, protected http: HttpClient) { }

  getJsonFile<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  getUFs(): Observable<UF[]> {
    return this.getJsonFile<UF[]>('./data/ufs.json');
    // return this.http.get<UF[]>('./data/ufs.json').pipe(
    //   map((response: any) => {
    //     let retorno: UF[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getPeriodicidades(): Observable<Periodicidade[]> {
    return this.getJsonFile<Periodicidade[]>('./data/periodicidades.json');
    // return this.http.request('data/periodicidades.json').pipe(
    //   map((response: Response) => {
    //     let retorno: Periodicidade[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getResponsaveisOva(): Observable<ResponsavelOva[]> {
    return this.getJsonFile<ResponsavelOva[]>('./data/responsaveisOva.json');
    // return this.http.request('data/responsaveisOva.json').pipe(
    //   map((response: Response) => {
    //     let retorno: ResponsavelOva[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getResponsaveisDesova(): Observable<ResponsavelDesova[]> {
    return this.getJsonFile<ResponsavelDesova[]>('./data/responsaveisDesova.json');
    // return this.http.request('data/responsaveisDesova.json').pipe(
    //   map((response: Response) => {
    //     let retorno: ResponsavelDesova[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getTipoEmbalagens(): Observable<TipoEmbalagem[]> {
    return this.getJsonFile<TipoEmbalagem[]>('./data/tipoEmbalagens.json');
    // return this.http.request('data/tipoEmbalagens.json').pipe(
    //   map((response: Response) => {
    //     let retorno: TipoEmbalagem[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getTiposOperacaoComercial(): Observable<TipoOperacaoComercial[]> {
    return this.getJsonFile<TipoOperacaoComercial[]>('./data/tiposOperacaoComercial.json');
    // return this.http.request('data/tiposOperacaoComercial.json').pipe(
    //   map((response: Response) => {
    //     let retorno: TipoOperacaoComercial[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getTiposPagadorFrete(): Observable<TipoPagadorFrete[]> {
    return this.getJsonFile<TipoPagadorFrete[]>('./data/tiposPagadorFrete.json');
    // return this.http.request('data/tiposPagadorFrete.json').pipe(
    //   map((response: Response) => {
    //     let retorno: TipoPagadorFrete[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getVeiculosEspeciais(): Observable<VeiculoEspecial[]> {
    return this.getJsonFile<VeiculoEspecial[]>('./data/veiculosEspeciais.json');
    // return this.http.request('data/veiculosEspeciais.json').pipe(
    //   map((response: Response) => {
    //     let retorno: VeiculoEspecial[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }

  getIncidentesBlocked(): Observable<Incidente[]> {
    return this.getJsonFile<Incidente[]>('./data/incidentesBlocked.json');
    // return this.http.request('data/incidentesBlocked.json').pipe(
    //   map((response: Response) => {
    //     let retorno: Incidente[] = response.json();
    //     return retorno;
    //   }),
    //   catchError(e => {
    //     return observableThrowError('');
    //   }))
    //   ;
  }
}
