import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RastreamentoService } from "app/_services/rastreamento.service";


@Component({
  selector: 'ordenacao-rastreamento',
  templateUrl: './ordenacao-rastreamento.component.html',
  styleUrls: ['./ordenacao-rastreamento.component.scss']
})
export class OrdenacaoRastreamentoComponent implements OnInit {
  @Output() exportarArquivo: EventEmitter<boolean> = new EventEmitter();
  @Output() quantidadePorPagina: EventEmitter<boolean> = new EventEmitter(); 
  @Output() ordenarTrackins: EventEmitter<string> = new EventEmitter();  

  ordenarpor: string;
  sentidoOrdenacao: string;

  constructor(private _rastreamentoService: RastreamentoService) {
    this.resetOrdenacao();
   }

  ngOnInit() { }

  resetOrdenacao(){
    this.ordenarpor = "numBooking";
    this.sentidoOrdenacao = "ASC";
  }

  exportarArquivoEmFormatoCSV(){
    this.exportarArquivo.emit(true);
  }

  ordenar(){
    this.ordenarTrackins.emit(this.ordenarpor + "|" + this.sentidoOrdenacao);
  }  

  mostrarQuantidadePorPagina(quantidadePorPagina):void{
    this.quantidadePorPagina.emit(quantidadePorPagina);
  }
}
