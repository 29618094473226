import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MultiselectDropdownModule } from './../../shared/multiselect-dropdown/multiselect-dropdown.module';
import { CnpjDropdownComponent } from './cnpj-dropdown.component';
import { MascaraModule } from "app/shared/mascara/mascara.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MultiselectDropdownModule,
        MascaraModule
    ],
    exports: [
        CnpjDropdownComponent
    ],
    declarations: [
        CnpjDropdownComponent
    ],
    providers: [],
})
export class CNPJDropDownModule { }
