import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MascaraDirective } from "app/_directives/mascara.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    MascaraDirective
  ],
  declarations: [
    MascaraDirective
  ]
})
export class MascaraModule { }
