import { Component, Input, QueryList, ViewChildren, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { EventoTracking } from '../../../../_models/evento-tracking';
import { TrackingLista } from 'app/_models/tracking-lista';
import { SlickSliderComponent } from 'app/shared/slick-slider/slick-slider-component';
import { InfoSil } from 'app/_models/info-sil';

@Component({
  selector: 'tracking-rastreamento',
  templateUrl: './tracking-rastreamento.component.html',
  styleUrls: ['./tracking-rastreamento.component.scss']
})
export class TrackingRastreamentoComponent {

  @ViewChildren(SlickSliderComponent) slickList: QueryList<SlickSliderComponent>;
  @Input() trackingLista: TrackingLista;

  silInfos: InfoSil[];

  @Input() set infoSil(infosSil: InfoSil[]) {
    this.silInfos = [];

    if(infosSil == undefined || infosSil.length == 0)
      return;

    this.silInfos = infosSil;
  }

  gruposTracking: EventoTracking[][];

  private _eventosTracking: EventoTracking[];
  @Input() set trackingDatas(tracking: EventoTracking[]){
    this._eventosTracking = tracking;
    this.gruposTracking = [];
    if ((tracking == undefined) || (tracking.length == 0))
      return;

    var grupo:EventoTracking[] = [];
    var ultimoGrupo = tracking[0].tipoEvento;
    tracking.forEach(item => {
      if (item.tipoEvento != ultimoGrupo){
        this.gruposTracking.push(grupo);
        grupo = [];
      }
      grupo.push(item);
      ultimoGrupo = item.tipoEvento;
    });

    if (grupo.length > 0)
      this.gruposTracking.push(grupo);

    this.reloadSlick();
  }

  temSilInfo(evento) {
    return this.silInfos.some(info => info.evento == evento);
  }

  NotIsEmColetaOrEmEntrega(eventoTracking) {
    return (eventoTracking.IsEmColeta && !['Coletada',"Booking Confirmado"].includes(this.trackingLista.statusBooking) ||
    (eventoTracking.IsEmEntrega && ['Realizada'].includes(this.trackingLista.statusBooking)))
  }

  get infoSil() {
    return this.silInfos;
  }

  get trackingDatas(): EventoTracking[]{
    return this._eventosTracking;
  }

  exibeCarouselTransbordo(grupo: EventoTracking[]): boolean {
    return grupo[0].IsTransbordo && grupo.length >= 2;
  }

  exibeItemLista(grupo: EventoTracking[]): string {
    return this.exibeCarouselTransbordo(grupo) ? 'item-transbordo' : '';
  }

  reloadSlick(){
    if (!this.slickList)
      return;

    this.slickList.forEach(slick => slick.reload());
  }
}
