import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OrdemServico } from 'app/_models/ordemServico';

import { SharedService } from 'app/_services/shared.service';

import { BaseComponent } from 'app/shared/base.component';

@Component({
  selector: 'item-detalhes-provedor',
  templateUrl: './item-detalhes-provedor.component.html',
  styleUrls: ['./item-detalhes-provedor.component.scss']
})

export class ItemDetalhesProvedorComponent extends BaseComponent implements OnInit {
  @Input() index: number;
  @Input() ordemServico: OrdemServico;
  @Input() exibirSelecao: boolean;
  @Input() exibirNumOs: boolean;
  @Input() exibirFatura: boolean;
  @Input() exibirTipoServico: boolean;
  @Input() exibirDataProgramacao: boolean;
  @Input() exibirEmbarque: boolean;
  @Input() exibirNavio: boolean;
  @Input() exibirContainer: boolean;
  @Input() exibirAcoes: boolean;
  @Input() exibirTracking: boolean;
  @Input() exibirMunicipio: boolean;
  @Input() classeLinha: string;
  @Input() titulo: string;
  @Input() acao: string;

  @Output() selecionarTodasOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() salvarOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() vincular: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() fecharOS: EventEmitter<OrdemServico> = new EventEmitter();

  constructor(private _sharedService: SharedService) {
    super();
  }

  ngOnInit() {
  }

  tituloColunaEmbDest(nomeEmbDest: string): string {
    var titulo: string;
    switch (nomeEmbDest.toLowerCase()) {
      case "coleta":
      case "exportação":
      case "frota dedicada":
        titulo = "Embarcador:";
        break;
      case "entrega":
      case "importação":
      case "fracionada":
        titulo = "Destinatário:";
        break;
      case "estadia":
      case "armazenagem":
      default:
        titulo = "Emb/Dest:";
    }

    return titulo;
  }

  tituloColunaPorto(nomeEmbDest: string): string {
    var titulo: string;
    switch (nomeEmbDest.toLowerCase()) {
      case "coleta":
      case "exportação":
      case "frota dedicada":
        titulo = "Porto Embarque";
        break;
      case "entrega":
      case "importação":
      case "fracionada":
        titulo = "Porto Desembarque";
        break;
      case "estadia":
      case "armazenagem":
      default:
        titulo = "Porto Emb/Des";
    }

    return titulo;
  }

  municipio(munic: string, uf: string) {
    return munic ? munic.trim() + "/" + uf : "";
  }

  emitirAcao(OS: OrdemServico) {
    if (this.acao == 'vincular')
      this.vincular.emit(OS);
    else if (this.acao == 'fecharOS')
      this.fecharOS.emit(OS);
  }

  formataString(texto: string) {
    return this._sharedService.toCamelCase(texto);
  }
  
  selecionarTodas() {
    this.selecionarTodasOSs.emit();
  }

  salvar() {
    this.salvarOSs.emit();
  }
}
