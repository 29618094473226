import { Transbordo } from "./transbordo";

export class Schedule {
    dhPrevChegadaPortoOrigem: Date;
    dhConfChegadaPortoOrigem: Date;
    dhPrevSaidaPortoOrigem: Date;
    dhConfSaidaPortoOrigem: Date;
    dhDeadlineEntregaEmbarque: Date;
    dhPrevChegadaPortoDestino: Date;
    dhConfChegadaPortoDestino: Date;
    dhPrevChegadaPortoTransbordo: Date;
    dhConfChegadaPortoTransbordo: Date;
    dhDeadlineDocumentacao: Date;

    idNavioTransbordo: number;
    idPortoTransbordo: number;
    transitTimetransbordo: number;
    idViagemNavio: number;
    idPortoOrigem: number;
    idPortoDestino: number;
    transitTime: number;

    descNavioTransbordo: string;
    descPortoTransbordo: string;
    ufPortoTransbordo: string;
    descPortoDestino: string;
    municPortoDestino: string;
    ufPortoDestino: string;
    descNavioViagem: string;
    descPortoOrigem: string;
    municPortoOrigem: string;
    ufPortoOrigem: string;

    strPrevChegadaPortoOrigem: string;
    strConfChegadaPortoOrigem: string;
    strPrevSaidaPortoOrigem: string;
    strConfSaidaPortoOrigem: string;
    strDeadlineEntregaEmbarque: string;
    strPrevChegadaPortoDestino: string;
    strConfChegadaPortoDestino: string;
    strPrevChegadaPortoTransbordo: string;
    strConfChegadaPortoTransbordo: string;
    strDeadlineDocumentacao: string;

    Transbordos: Transbordo[];
}