export class DadosAba3{
    public cep: string;
    public logradouro: string;
    public numero: string;
    public complemento: string;
    public bairro: string;
    public idMunicipio: number;
    public municipio: string;
    public contato: string;
    public ddd: string;
    public telefone: string;
    public observacoes: string;
    public uf: string;
}