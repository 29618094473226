import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UploadArquivosComponent } from './uploadarquivos.component';
import { AppService } from 'app/_services/app.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        UploadArquivosComponent
    ],
    declarations: [
        UploadArquivosComponent
    ],
    providers: [AppService],
})
export class UploadArquivosModule { }
