import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DatePickerComponent } from './ng2-datepicker.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SlimScrollModule } from 'app/shared/slimscroll/slimscroll.module';
export { DatePickerOptions, DateModel } from './ng2-datepicker.component';

@NgModule({
  declarations: [
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    TextMaskModule,
    FormsModule,
    SlimScrollModule
  ],
  exports: [
    DatePickerComponent,
    SlimScrollModule,
    FormsModule
  ]
})
export class DatePickerModule { }