import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiselectDropdown, MultiSelectSearchFilter } from './multiselect-dropdown.component'

@NgModule({
    imports: [CommonModule, FormsModule],
    exports: [MultiselectDropdown, MultiSelectSearchFilter],
    declarations: [MultiselectDropdown, MultiSelectSearchFilter],
  })
  export class MultiselectDropdownModule {
  }