import { Directive, HostListener, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[Mascara]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MascaraDirective,
    multi: true
  }]
})
export class MascaraDirective implements ControlValueAccessor {

  onTouched: any;
  onChange: any;

  @Input('Mascara') Mascara: string;

  constructor(private el: ElementRef, private _changeDetect: ChangeDetectorRef) {}

  writeValue(value: any): void {
    if (value) {
      this.el.nativeElement.value = this.aplicarMascara(value);
    }
    else {
      this.el.nativeElement.value = "";
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this._changeDetect.detectChanges();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {

    let valor = $event.target.value.replace(/\D/g, '');

    // retorna caso pressionado backspace
    if ($event.keyCode === 8) {
      this.onChange(valor);
      return;
    }

    let pad = this.Mascara.replace(/\D/g, '').replace(/9/g, '_');
    if (valor.length <= pad.length) {
      this.onChange(valor);
    }

    $event.target.value = this.aplicarMascara(valor);

  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length === this.Mascara.length) {
      return;
    }
    else
      $event.target.value = this.aplicarMascara($event.target.value);

    this.onChange($event.target.value);
    if ($event.target.value.length === this.Mascara.length) {
      return;
    }

    // this.onChange('');
    // $event.target.value = '';
  }

  aplicarMascara(valor: string): string {
    valor = valor.replace(/\D/g, '');
    let pad = this.Mascara.replace(/\D/g, '').replace(/9/g, '_');
    let valorMask = valor + pad.substring(0, pad.length - valor.length);
    let valorMaskPos = 0;
    
    //---- se for telefone, verifica 9º dígito ----
    var aux = valorMask.replace('_', '');
    if ((aux.length == 10) && (this.Mascara = "(99) 99999-9999")) {
      this.Mascara = "(99) 9999-9999";
    }
    else {
      if ((aux.length == 11) && (this.Mascara = "(99) 9999-9999")) {
        this.Mascara = "(99) 99999-9999";
      }
    }
    //------------------------------------------

    valor = '';
    for (let i = 0; i < this.Mascara.length; i++) {
      if (isNaN(parseInt(this.Mascara.charAt(i)))) {
        valor += this.Mascara.charAt(i);
      } else {
        valor += valorMask[valorMaskPos++];
      }
    }
    
    if (valor.indexOf('_') > -1) {
      valor = valor.substr(0, valor.indexOf('_'));
    }

    return valor;
  }
}