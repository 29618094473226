import { Component } from '@angular/core';

export class BaseComponent {

    public hiddenInput(input: any, style: string = ''): string {
        return this.hidden(input.valid || !input.touched, style);
    }

    /**
     * hidden
     * condicao: boolean     */
    public hidden(condicao: boolean, style: string = ''): string {
        return condicao ? 'none' : style + 'block';
    }
}