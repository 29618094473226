import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NaviosComponent } from './navios/navios.component';
import { HorasComponent } from './horas/horas.component';
import { TiposServicosComponent } from './tipos-servicos/tipos-servicos.component';
import { ParametroComponent } from './parametro.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { TerminaisComponent } from './terminais/terminais.component';
import { PrioridadesComponent } from './prioridades/prioridades.component';

const paramsRoutes: Routes = [
    { path: '', component: ParametroComponent, children : [
        { path: 'horas', component: HorasComponent, canActivate: [AuthGuard] },
        { path: 'navios', component: NaviosComponent, canActivate: [AuthGuard] },
        { path: 'servicos', component: TiposServicosComponent, canActivate: [AuthGuard] },
        { path: 'terminais', component: TerminaisComponent, canActivate: [AuthGuard] },
        { path: 'prioridades', component: PrioridadesComponent, canActivate: [AuthGuard] },
        { path: '**', redirectTo: 'horas' },
    ] },
];

@NgModule({
    imports: [RouterModule.forChild(paramsRoutes)],
    exports: [RouterModule]
})
export class ParamentroRoutingModule {

}
