import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'app/_guards/auth.guard';
import { VincularNfComponent } from './vincular-nf.component';

const vincularNfRoutes: Routes = [
    { path: '', component: VincularNfComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(vincularNfRoutes)],
    exports: [RouterModule]
})
export class VincularNfRoutingModule {}