import { Component, OnInit, Input } from '@angular/core';

import { CtmNf } from 'app/_models/ctmNf';
import { BaseComponent } from 'app/shared/base.component';

@Component({
  selector: 'chave-cte',
  templateUrl: './chave-cte.component.html',
  styleUrls: ['./chave-cte.component.scss']
})

export class ChaveCteComponent extends BaseComponent implements OnInit {
  @Input() cte: string;
  @Input() index: number;

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
