import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CadastrarSenhaComponent } from './cadastrar-senha.component';
import { CadastrarSenhaRoutingModule } from './cadastrar-senha.routing.module';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CadastrarSenhaRoutingModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [ CadastrarSenhaComponent ],
    providers: [],
})
export class CadastrarSenhaModule {  }
