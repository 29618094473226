import { Component, Input } from '@angular/core';

import { TipoContainer } from '../../_models/tipo-container';

@Component({
  selector: 'detalhes-container',
  templateUrl: './detalhes-container.component.html',
  styleUrls: ['./detalhes-container.component.scss']
})
export class DetalhesContainerComponent {

  @Input() titulo: string;
  @Input() divClass: string;
  @Input() tooltipPlacement: string;
  @Input() modal: string;
  @Input() cargaPerigosa: string;
  @Input() cargaExcesso: string;
  @Input() cargaRefrigerada: string;
  @Input() tipoContainer: TipoContainer;
  @Input() exibeDescricaoResumida: boolean = true;

  obtemClasseDoTipoContainer(): string {
    var especial = "icon icon-lightbulb-o";
    var container =
      {
        dry: "icon icon-dry",
        reefer: "icon icon-reefer",
        openTop: especial,
        flatRack: especial,
        tank: especial,
        padrao: ""
      };
    return this.processaTipoContainer(container);
  }
  
  obtemIconeDoContainer(): string {
    var tamanho = this.tipoContainer.Tamanho;
    var container =
      {
        dry: "standard_" + tamanho,
        reefer: "reefer_" + tamanho,
        openTop: "open_top_" + tamanho,
        flatRack: "flat_rack_" + tamanho,
        tank: "standard_" + tamanho,
        padrao: "standard_40"
      };
    return this.processaTipoContainer(container);
  }

  private processaTipoContainer(container: {dry: string, reefer: string, openTop: string, flatRack: string, tank: string, padrao: string}): string {
    if ((this.tipoContainer == undefined) || 
        (this.tipoContainer.Desc_resumida == undefined) || 
        (this.tipoContainer.Desc_resumida.length < 2))
      return container.padrao;
    
    var descricaoResumida = this.tipoContainer.Desc_resumida;
    var identificador = descricaoResumida.substring(descricaoResumida.length - 3);
    switch (identificador.trim()){
      case "DC":
      case "HC":
        return container.dry;
      case "RF":
      case "RH":
        return container.reefer;
      case "OT":
      case "OH":
        return container.openTop;
      case "FR":
      case "FH":
        return container.flatRack;
      case "TK":
        return container.tank;
      default:
        return container.padrao;
    }
  }

  obtemClasseIconeOrigem(){
    return this.obtemClasseDoIconeDaModalidade(this.modal.substring(0, 2));
  }

  obtemClasseIconeDestino(){
    return this.obtemClasseDoIconeDaModalidade(this.modal.substring(3));
  }

  private obtemClasseDoIconeDaModalidade(parteDaModalidade: string): string {
    return parteDaModalidade == "PA" ? "icon icon-home" : "icon icon-port";
  }

}
