import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'app/_guards/auth.guard';
import { LogComponent } from './log.component';

const logRoutes: Routes = [
    { path: '', component: LogComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(logRoutes)],
    exports: [RouterModule]
})
export class LogRoutingModule {}