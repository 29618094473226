export class OkEntregaConsultaLink {
  booking: string;
  numNF: string;
  linkCanhoto: string;

  constructor(booking: string, numNF: string) {
    this.booking = booking;
    this.numNF = numNF;
  }
}
