import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { AnalyticsDownload } from '../../_enums/analytics-download';

@Component({
	selector: 'ordenacao-provedor',
	templateUrl: './ordenacao-provedor.component.html',
	styleUrls: ['./ordenacao-provedor.component.scss']
})

export class OrdenacaoProvedorComponent implements OnInit {
	@Input() titulo: string;
	@Input() listaCampos: any;
	@Input() ordenarPor: string;

	@Output() exportarArquivo: EventEmitter<boolean> = new EventEmitter();
	@Output() quantidadePorPagina: EventEmitter<boolean> = new EventEmitter();
	@Output() ordenarLista: EventEmitter<string> = new EventEmitter();

	constructor(private _analyticsService: AnalyticsService) {
	}

	ngOnInit() {
	}

	exportarArquivoEmFormatoCSV() {
		var tela = (this.titulo.includes('Vincular')) ? AnalyticsTela.VincularNF : AnalyticsTela.OrdemServico;
		this._analyticsService.RegistrarDownload(tela, AnalyticsDownload.ExportacaoCSV);
		this.exportarArquivo.emit(true);
	}

	ordenar() {
		this.ordenarLista.emit(this.ordenarPor);
	}

	mostrarQuantidadePorPagina(quantidadePorPagina): void {
		this.quantidadePorPagina.emit(quantidadePorPagina);
	}
}
