import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'app/_guards/auth.guard';
import { PrioridadesComponent } from './prioridades.component';

const _routes: Routes = [
  { path: '', component: PrioridadesComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(_routes)],
  exports: [RouterModule]
})
export class PrioridadesRoutingModule {}
