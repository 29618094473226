import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

import { TrackingCteNf } from 'app/_models/tracking-cte-nf';
import { TrackingLista } from './../../../_models/tracking-lista';
import { TipoEventoTracking } from '../../../_models/tipoEventoTracking';
import { TrackingListaRequest } from './../../../_models/trackinglista-request';
import { TrackingRastreamentoComponent } from 'app/cliente/rastreamento/lista-rastreamento/tracking-rastreamento/tracking-rastreamento.component';

import { AppService } from "app/_services/app.service";
import { SharedService } from 'app/_services/shared.service';
import { RastreamentoService } from 'app/_services/rastreamento.service';
import { AgendaEntregaService } from 'app/_services/agenda-entrega.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';

import { BaseComponent } from './../../../shared/base.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { AgendamentoRastreamentoComponent } from 'app/cliente/rastreamento/agendamento-rastreamento/agendamento-rastreamento.component';
import { PaginaParametroPorto } from 'app/_models/pagina-parametro-porto';
import { DetalhesContainerComponent } from 'app/shared/detalhes-container/detalhes-container.component';
import { ParametroService } from 'app/_services/parametro.service';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { AnalyticsOperacao } from 'app/_enums/analytics-operacao';
import { DetalhesTrackingRequest } from 'app/_models/detalhes-tracking-request';
import { Co2Total } from 'app/_models/co2-total';

@Component({
    selector: 'lista-rastreamento',
    templateUrl: './lista-rastreamento.component.html',
    styleUrls: ['./lista-rastreamento.component.scss']
})
export class ListaRastreamentoComponent extends BaseComponent implements OnInit {

    // ViewChilds
    @ViewChild(TrackingRastreamentoComponent, {static: true}) trackingRastreamento: TrackingRastreamentoComponent;
    @ViewChild(AgendamentoRastreamentoComponent, {static: true}) agendamentoRastreamento: AgendamentoRastreamentoComponent;
    @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
    @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;
    @Output() carregaFiltrosDeRastreamento: EventEmitter<TrackingListaRequest> = new EventEmitter();
    @Output() visualizarMapaEmmiter: EventEmitter<any> = new EventEmitter();
    @Output() totalCo2: EventEmitter<Co2Total> = new EventEmitter();
    // Variáveis
    rastreamentos: TrackingLista[] = [];
    tpEventos: TipoEventoTracking[] = [];
    paginaParametroPorto: PaginaParametroPorto;
    filtroPesquisaPrincipal: TrackingListaRequest;

    constructor(private _sharedService: SharedService,
        private _genericOrderbyService: GenericOrderbyService,
        private _rastreamentoService: RastreamentoService,
        private _agendaEntregaService: AgendaEntregaService,
        private _parametroService: ParametroService,
        private _appService: AppService,
        private _analyticsService: AnalyticsService) {
        super();
    }

    ngOnInit() {
        this.agendamentoRastreamento.carregaFiltrosDeRastreamento.subscribe(
            (filtro: TrackingListaRequest) => {
                //this.pesquisar(filtro);
                this.carregaFiltrosDeRastreamento.emit(null);
            });
    }

    setRastreamentoModal(id: number, soLeitura: boolean = false) {
        const rastreios = this.rastreamentos.filter(track => track.idBooking === id);
        const parametro = this._parametroService.obterParametros(this.paginaParametroPorto, rastreios[0].idPortoDestino);
        this.agendamentoRastreamento.qtdHorasEntregaETA = parametro.QtdHrAgendEntrega;
        this.agendamentoRastreamento.qtdMinHorasEntrega = parametro.QtdHrMinimasAgendEntrega;
        this.agendamentoRastreamento.exibeMensagemErro('');//.mensagemErro = '';
        this.agendamentoRastreamento.setRastreamento(rastreios, soLeitura);
        this._analyticsService.RegistrarOperacao(AnalyticsTela.Rastreamento, AnalyticsOperacao.AgendamentoVisualizado);
        this._agendaEntregaService.showModal();
    }

    downloadDoc(cteNf: TrackingCteNf) {
    }

    carregaListaTrackins(trackings: TrackingLista[]) {
        if (trackings) {
            this.rastreamentos = trackings;
            const compensouCo2 = this.rastreamentos.some(x => x.indicEcoFrete == true);
            const totalCo2 = this.rastreamentos.filter(x => x.indicEcoFrete == compensouCo2).map(x => x.co2 || 0).reduce((x, total) => x + total, 0);
            const co2Total = new Co2Total();
            co2Total.co2 = totalCo2;
            co2Total.indicEcofrete = compensouCo2;

            this.totalCo2.emit(co2Total);
            this.paginacao.setAllData(this.rastreamentos);
        }
    }

    obtemListaTrackingOrdenadaPorCampo(ordenacao: string) {
        if (this.rastreamentos) {
            var dados = ordenacao.split('|');
            var campo = dados[0];
            var descendente = dados[1] == "DESC";
            this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.rastreamentos, campo, descendente));
        }
    }

    setQuantidadeRegistrosPorPagina(quantidadePorPagina: number) {
        this.paginacao.setPageSize(quantidadePorPagina);
    }

    setTiposEventos(tiposEventos: TipoEventoTracking[]): void {
        this.tpEventos = tiposEventos;
    }

    exibirErroModal(mensagemErro: string) {
        this.agendamentoRastreamento.exibeMensagemErro(mensagemErro); //.mensagemErro = mensagemErro;
    }

    obtemClasseDoStatus(status: string): string {
        if (status) {
            status = status.toLowerCase();
            if (status.indexOf('emitido') !== -1) {
                return 'quotation-status active';
            } else if (status.indexOf('pendente') !== -1) {
                return 'quotation-status waiting';
            }
            else if (status.indexOf('ativo') !== -1) {
                return 'quotation-status info';
            }
            return 'quotation-status expired';
        }
        return 'quotation-status waiting';
    }

    obtemClassDoTipoDeEvento(statusBooking: string): string {
        if (statusBooking) {
            const descricaoTpEvento: string = statusBooking.trim().toLowerCase();
            switch (descricaoTpEvento) {
                case 'booking confirmado':
                    return 'product-cargas confirmadas';
                case 'coletada':
                    return 'product-cargas coletadas';
                case 'depositada':
                    return 'product-cargas depositadas';
                case 'navegando':
                    return 'product-cargas navegando';
                case 'desembarcada':
                    return 'product-cargas desembarcadas';
                case 'em entrega':
                    return 'product-cargas em-entrega';
                case 'realizada':
                    return 'product-cargas realizadas';
                case 'devolução vazio':
                      return 'product-cargas devolucao-vazio';
                case 'retirada cheio':
                        return 'product-cargas retirada-cheio';
                default:
                    return 'product-cargas coletadas';
            }
        }

        return 'product-cargas coletadas';
    }

    visualizarDetalhesDoTracking(tracking: TrackingLista): void {
        if (tracking) {
            this._analyticsService.RegistrarOperacao(AnalyticsTela.Rastreamento, AnalyticsOperacao.ConsultaRastreamento);
            tracking.loading = true;
            var filtro = new DetalhesTrackingRequest();
            filtro.idBooking = tracking.idBooking;
            filtro.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
            filtro.numContainer = tracking.numContainer;
            filtro.cnpjCli = this.filtroPesquisaPrincipal.cnpjCli; // tracking.cnpjCli;
            filtro.numBooking = tracking.numBooking;
            filtro.idAgendColeta = tracking.idAgendColeta;
            filtro.idBooking = tracking.idBooking;

            if ((tracking.detalhes == null) || (tracking.detalhes && tracking.detalhes.length == 0)) {
                tracking.detalhes = [];
                tracking.arquivosCTeNf = [];
                tracking.infosSil = [];
                tracking.loading = true;

                this._rastreamentoService.obtemDetalhesTracking(filtro)
                .subscribe(detalhes => {
                    if (!detalhes){
                      tracking.loading = false;
                      return;
                    }

                    tracking.detalhes = detalhes.EventosTracking;
                    tracking.arquivosCTeNf = detalhes.CTesTracking;
                    tracking.verNoMapa = detalhes.ExibeVerNoMapa;
                    tracking.CanhotosDisponiveis = detalhes.CanhotosDisponiveis;

                    tracking.infosSil = detalhes.SilViewModels;
                    tracking.loading = false;
                },
                (err) => console.log(err)
                );
            }
            else
              tracking.loading = false;
        }
    }

    temSilInfo(tracking, evento){
      return tracking.infosSil && tracking.infosSil.some(e => e === evento);
    }

    changeEmColeta(str: string){
        if(str && str === 'Coletada')
            return "Em coleta";
        return str;
    }

    getContainer(str: string) {
        if (!str)
            return "";
        return (str != "Indeterminado") ? str.toUpperCase() : str;
    }

    obtemCidadeEmbarcador(track: TrackingLista): string {
        if (!track || !track.modal)
            return "";

        return (track.modal.startsWith('PA')) ? this._sharedService.toCamelCase(track.municColeta) :
                                this._sharedService.toCamelCase(track.portoOri);
    }

    obtemCidadeDestinatario(track: TrackingLista): string {
        if (!track || !track.modal)
            return "";

        return (track.modal.endsWith('PA')) ? this._sharedService.toCamelCase(track.municEntrega) :
                                    this._sharedService.toCamelCase(track.portoDes) ;
    }

    VisualizarMapaTracking(track: TrackingLista) {
      this.visualizarMapaEmmiter.emit(track);
    }

}
