import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ordenacao-documentacao',
  templateUrl: './ordenacao-documentacao.component.html',
  styleUrls: ['./ordenacao-documentacao.component.scss']
})
export class OrdenacaoDocumentacaoComponent {

  @Output() alteradoPageSize: EventEmitter<number> = new EventEmitter<number>();
  @Output() alteradaOrdenacao: EventEmitter<string> = new EventEmitter<string>();
  
  changePageSize(pageSize: number){
    this.alteradoPageSize.emit(pageSize);
  }

  changeOrdenacao(ordenacao: string){
    this.alteradaOrdenacao.emit(ordenacao);
  }
}
