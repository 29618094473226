import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let maxLength = args || 0;
    let length = value.length;

    return (maxLength - length);
  }

}

