export class TipoContainer {
    Codigo: number = 0;
    Tamanho: number = 0;
    Descricao: string = "";
    Desc_resumida: string = "";
    Flag_PES: string = "";
    Flag_Reefer: string = "";
    Flag_HighCube: string = "";
    Flag_Dry: string = "";
    
    private _idContainer: string = "";
    get idContainer(): string {
        if (this._idContainer == "") {
            this._idContainer = (this.Codigo != null) ? this.Codigo.toString() + "|" + this.Tamanho.toString() : undefined;
        }
        return this._idContainer;
    }
    set idContainer(value) {
        this._idContainer = value;
    }
}
