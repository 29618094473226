import { Funcionalidade } from './funcionalidade';

export class Perfil{
    PerfilID: number;
    Descricao: string;
    Ativo: boolean;
    Funcionalidades: Funcionalidade[];
    TotalUsuarios: number;
    Cliente: boolean;
    Provedor: boolean;
    Admin: boolean;
}