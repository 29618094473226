import { Component, Input } from '@angular/core';

import { Anel } from '../../../../_models/anel';
import { Porto } from '../../../../_models/porto';
import { AnelRota } from '../../../../_models/anel-rota';

import { MessageService } from '../../../../_services/message.service';

import { Operacao } from 'app/_enums/operacao';
import { SentidoRota } from '../../../../_enums/sentido-rota.enum';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'rotas-anel',
  templateUrl: './rotas-anel.component.html',
  styleUrls: ['./rotas-anel.component.scss']
})
export class RotasAnelComponent {
  @Input() anel: Anel;
  @Input() titulo: string;
  @Input() portos: Porto[];
  @Input() sentido: SentidoRota;
  public rotas: AnelRota[];

  constructor(private _msgService: MessageService) { }

  public CarregarRotas(rotas: AnelRota[]){
    this.rotas = rotas;
  }

  public AdicionarRota(index: number) {
    if (!this.ValidaRota(index))
      return;
      
    if (index > 0){
      this.rotas[index - 1].Novo = false;
      this.rotas[index - 1].Ultimo = false;
    }
    
    this.rotas[index].Ultimo = true;
    this.rotas[index].Novo = false;
    this.rotas[index].Sentido = this.sentido;
    this.rotas[index].DescricaoPortoDestino = this.portos.find(p => p.Codigo == this.rotas[index].CodigoPortoDestino).Descricao;
    if (this.rotas[index].DescricaoPortoOrigem == "")
      this.rotas[index].DescricaoPortoOrigem = this.portos.find(p => p.Codigo == this.rotas[index].CodigoPortoOrigem).Descricao;

    this.SetUltimoNovo(this.anel.AnelID);
  }

  public ExcluirRota(index: number) {
    this.rotas.splice(this.rotas.length - 1, 1);
    this.rotas.splice(index, 1);
    this.SetUltimoNovo(this.anel.AnelID);
  }

  public ValidaRota(index: number): boolean {
    if (index == 0 && this.rotas[index].CodigoPortoOrigem == 0) {
      this._msgService.addMessage('Adicionar Rota', 'Selecione um Porto Origem!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    if (index == 0 && this.rotas[index].CodigoPortoOrigem == this.rotas[index].CodigoPortoDestino) {
      this._msgService.addMessage('Adicionar Rota', 'O Porto Destino deve ser diferente do Porto Origem!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    if (this.rotas[index].CodigoPortoDestino == 0) {
      this._msgService.addMessage('Adicionar Rota', 'Selecione um Porto Destino!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    let jaUsado = false;
    for (let i = 0; i < this.rotas.length - 1; i++) {
      if (this.rotas[i].CodigoPortoOrigem == this.rotas[index].CodigoPortoDestino ||
        this.rotas[i].CodigoPortoDestino == this.rotas[index].CodigoPortoDestino) {
        jaUsado = true;
      }
    }
    if (jaUsado) {
      this._msgService.addMessage('Adicionar Rota', 'Porto já utilizado!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }
    return true;
  }

  public SetUltimoNovo(anelID: number) {
    let totalRotas: number;
    let novaRota = new AnelRota();

    if (this.rotas == undefined) {
      this.rotas = [];
      totalRotas = 0;
    }
    else
      totalRotas = this.rotas.length;

    if (totalRotas == 0) {
      novaRota.Ultimo = true;
      novaRota.Ordem = 1;
      novaRota.CodigoPortoOrigem = 0;
      novaRota.DescricaoPortoOrigem = "";
    }
    else {
      this.rotas[totalRotas - 1].Ultimo = true;
      novaRota.Ordem = this.rotas[totalRotas - 1].Ordem + 1;
      novaRota.CodigoPortoOrigem = this.rotas[totalRotas - 1].CodigoPortoDestino;
      novaRota.DescricaoPortoOrigem = this.rotas[totalRotas - 1].DescricaoPortoDestino;
    }

    novaRota.AnelRotaID = 0;
    novaRota.CodigoPortoDestino = 0;
    novaRota.DescricaoPortoDestino = "";
    novaRota.AnelID = anelID;
    novaRota.Novo = true;

    this.rotas.push(novaRota);
  }

  public ContemRotas(): boolean{
    return !(this.rotas == undefined || this.rotas.length == 0 || this.rotas[0].Novo == true);
  }

  public AdicionaRotaPreenchida(){
    let ultimo = this.rotas.length-1;
    if (this.rotas[ultimo].CodigoPortoDestino != 0)
      this.AdicionarRota(ultimo);
  }
}
