export enum AnalyticsFiltro {
    CNPJ = "CNPJ",
    FiltroGenerico = "Filtro Genérico",
    FiltroGenerico2 = "Filtro Genérico 2",
    NavioViagem = "Navio Viagem",
    TipoOS = "Tipo de OS",
    Porto = "Porto",
    TipoServico = "Tipo de Serviço",
    Periodo10dias = "Período - Últimos 10 dias",
    Periodo15dias = "Período - Últimos 15 dias",
    Periodo30dias = "Período - Últimos 30 dias",
    PeriodoDeAte = "Período - De/Até",
    PeriodoAPartirDe = "Período - A partir de",
    PeriodoChegadaAte = "Período - Chegada até",
    Embarque = "Embarque",
    Desembarque = "Desembarque",
    Origem = "Origem",
    Destino = "Destino",
    IE = "Inscrição Estadual"
}