import { Transbordo } from './transbordo';
import { PropostaComercial } from './proposta-comercial';
import { AgendamentoColeta } from './agendamento-coleta';
import { TipoContainer } from 'app/_models/tipo-container';
import { Cliente } from 'app/_models/cliente';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Booking {
    idBooking: number = 0;
    idProposta: number = 0;
    idTrecho: number = 0;
    idMunicEmb: number = 0;
    idCategoriaContainer: number = 0;
    tamanhoContainer: number = 0;
    qtdeContainer: number = 0;
    tipoContainer: string = "";
    idNavioViagem: number = 0;
    temperatura: number = 0;
    cargaOverAltura: number = 0;
    cargaOverEsquerda: number = 0;
    cargaOverDireita: number = 0;
    cargaOverFrontal: number = 0;
    idUsuario: number = 0;
    cargaOverTraseiro: number = 0;
    cnpjCli: string = '';
    ieCli: string = '';
    contatoCli: string = '';
    Embarcador: Cliente = new Cliente();
    nomeEmbarcador: string = '';
    cnpjEmb: string = '';
    ieEmb: string = '';
    cepEmb: string = '';
    logradouroEmb: string = '';
    numeroEmb: string = '';
    numeroEndEmb: string = '';
    complementoEmb: string = '';
    bairroEmb: string = '';
    contatoEmb: string = '';
    dddEmb: string = '';
    foneEmb: string = '';
    telefone: string = '';
    email: string = '';
    emailEmb: string = '';
    modal: string = '';
    classe: string = '';
    sequencia: string = '';
    numUno: string = '';
    psn: string = '';
    obsCargaPerigosa: string = '';
    obsCargaRefrigerada: string = '';
    obsCargaOver: string = '';
    numBooking: string = '';
    numProposta: string = '';
    filtroGenerico: string = '';
    obsGeral: string = '';
    flagCargaPerigosa: string = '';
    flagCargaRefrigerada: string = '';
    flagCargaOver: string = '';
 
    pesoBruto: number = 0;
    idViagemNavio: number = 0;
    viagemNavio: string = '';
    idPortoOri: number = 0;
    portoOri: string = '';
    ufPortoOri: string = '';
    idMunicOri: number = 0;
    municOri: string = '';
    ufMunicOri: string = '';
    idNavioViagemTransb: number = 0;
    NavioViagemTransb: string = '';
    portoTransb: string = '';
    ufPortoTransb: string = '';
    idPortoTransb: number = 0;
    idMunicDes: number = 0;
    municDes: string = '';
    ufDes: string = '';
    idPortoDes: number = 0;
    portoDes: string = '';
    ufPortoDes: string = '';
    statusBooking: string = '';
    statusProposta: string = '';
    nomeEmb: string = '';
    municipioEmb: string = '';    
    dhPrevEmbarque: Date = new Date();
    dhPrevDesembarque: Date = new Date();
    strPrevEmbarque: string;
    strPrevDesembarque: string;
    categoriaContainer: string = '';

    DataPrevChegadaDes: Date;
    DataPrevChegadaOri: Date;
    DataDeadlineEntregaEmb: Date;
    strDataPrevChegadaDes: string;
    strDataPrevChegadaOri: string;
    strDataDeadlineEntregaEmb: string;

    idsPortoOrigem: string = '';
    idsMunicOrigem: string = '';
    idsMunicDestino: string = '';
    idsPortoDestino: string = '';
    flagEscoltaOrigem: string = '';
    flagEscoltaDestino: string = '';
    ufEmb: string = '';
    idMunicipioEmb: number = 0;
    obsRejeicaoCli: string = '';
    flagGenset: string = '';
    seguro: string = '';
    adValorem: number = 0;

    dataIni: Date = new Date();
    dataFim: Date = new Date();

    status: string; 
    loading: boolean;

    idNavioTransbordo: number = 0;
    idPortoTransbordo: number = 0;

    tipoDeContainer : TipoContainer;

    detalheDoBooking: Booking;    

    AgendamentosDeColeta: AgendamentoColeta[];
    proposta: PropostaComercial;

    usuarioSolicitantePortal: string;
    dataHoraSolicitacao: Date;
    servicosAdicionais: string;

    indicPagtoAVista: string = '';

    Transbordos: Transbordo[];
//-------------------------
//DAQUI PRA BAIXO SÃO PROPRIEDADES/FUNÇÕES CUSTOMIZADAS
    get telefoneEmbarcadorComDDD(): string {
        return this.dddEmb + this.foneEmb;
    }

    private _oriPorto: boolean = true;
    get oriPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.startsWith('/')))
            return undefined;
        this._oriPorto = this.modal.startsWith("PO");

        return this._oriPorto;
    }
    set oriPorto(value: boolean) {
        this._oriPorto = value;
        this.atualizaModal('ori');
    }

    private _desPorto: boolean = true;
    get desPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.endsWith('/')))
            return undefined;

        this._desPorto = this.modal.endsWith("PO");
        return this._desPorto;
    }
    set desPorto(value: boolean) {
        this._desPorto = value;
        this.atualizaModal('des');
    }

    private atualizaModal(tipo: string) {
        //PO/PO
        if (tipo == 'ori') {
            if (this.desPorto != undefined) {
              this.modal = (this._oriPorto ? "PO" : "PA") + "/" + (this._desPorto ? "PO" : "PA");
            }
            else
                this.modal = (this._oriPorto ? "PO" : "PA") + "/";
        }
        else {
            if (this.oriPorto != undefined)
                this.modal = (this._oriPorto ? "PO" : "PA") + "/" + (this._desPorto ? "PO" : "PA");
            else
                this.modal = "/" + (this._desPorto ? "PO" : "PA");
        }

        if ((this.modal != undefined) && (this.modal != ""))
            this.modal = this.modal.replace("//", "/");

    }

    private _cargaPerigosa: boolean = false;
    get cargaPerigosa(): boolean {
        this._cargaPerigosa = this.flagCargaPerigosa == 'S';
        return this._cargaPerigosa;
    }
    set cargaPerigosa(value: boolean) {
        this._cargaPerigosa = value;
        this.flagCargaPerigosa = this._cargaPerigosa ? 'S' : 'N';
    }

    private _cargaRefrigerada: boolean = false;
    get cargaRefrigerada(): boolean {
        if ((this.flagCargaRefrigerada == undefined) || (this.flagCargaRefrigerada == ""))
            return undefined;
        this._cargaRefrigerada = this.flagCargaRefrigerada == 'S';
        return this._cargaRefrigerada;
    }
    set cargaRefrigerada(value: boolean) {
        this._cargaRefrigerada = value;
        this.flagCargaRefrigerada = this._cargaRefrigerada ? 'S' : 'N';
    }

    private _cargaOver: boolean = false;
    get cargaOver(): boolean {
        if ((this.flagCargaOver == undefined) || (this.flagCargaOver == ""))
            return undefined;
        this._cargaOver = this.flagCargaOver == 'S';
        return this._cargaOver;
    }
    set cargaOver(value: boolean) {
        this._cargaOver = value;
        this.flagCargaOver = this._cargaOver ? 'S' : 'N';
    }

    private _pagtoAVista: boolean = false;
    get pagtoAVista(): boolean {
        this._pagtoAVista = this.indicPagtoAVista == 'S';
        return this._pagtoAVista;
    }
    set pagtoAVista(value: boolean) {
        this._pagtoAVista = value;
        this.indicPagtoAVista = this._pagtoAVista ? 'S' : 'N';
    }

    setFlagsEnvio() {
        if (this.flagCargaRefrigerada == '' || "SN".indexOf(this.flagCargaRefrigerada) === -1)
            this.flagCargaRefrigerada = (this.cargaRefrigerada == undefined) ? "N" : (!this.cargaRefrigerada) ? 'N' : 'S';

        if (this.flagCargaOver == '' || "SN".indexOf(this.flagCargaOver) === -1)
            this.flagCargaOver = (this.cargaOver == undefined) ? "N" : (!this.cargaOver) ? 'N' : 'S';

        if (this.flagCargaPerigosa == '' || "SN".indexOf(this.flagCargaPerigosa) === -1)
            this.flagCargaPerigosa = (this.cargaPerigosa == undefined) ? "N" : (!this.cargaPerigosa) ? 'N' : 'S';
    }

    obtemTextoEmbarque() {
        if (!this || !this.modal)
            return '';

        if (this.modal.startsWith('PO'))
            if(this.portoDes)
                return (this.portoOri + ' - ' + this.ufPortoOri).toUpperCase();
            else {
                if (!this.proposta)
                    return '';
                else
                    return (this.proposta.nomePortoOrigem + ' - ' + this.proposta.ufPortoOrigem).toUpperCase();
            }
        else
          return (this.municOri + ' - ' + this.ufMunicOri).toUpperCase();
    }
    
    obtemTextoDesembarque() {
        if (!this || !this.modal)
            return '';

        if (this.modal.endsWith('PO'))
            if(this.portoDes)
                return (this.portoDes + ' - ' + this.ufPortoDes).toUpperCase();
            else {
                if (!this.proposta)
                    return '';
                else
                    return (this.proposta.nomePortoDestino + ' - ' + this.proposta.ufPortoDestino).toUpperCase();
            }
        else
          return (this.municDes + ' - ' + this.ufDes).toUpperCase();
    }
}
