export class CtmNf {
	acao: number = 0;
	chaveCtm: string = '';
	chaveNfe: string = '';
	numContainer: string = '';
	numCtm: string = '';
	numNF: string = '';
	serieNF: string = '';
	statusNF: string = '';

	idOs: number = 0;
	idUsuario: number = 0;

	numAverbacao: string = '';
	numApolice: string = '';
}
