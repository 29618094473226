import { Injectable } from "@angular/core";

import * as moment from "moment/moment";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";

export class DateExtensions {
  static toDateModel(date: any, format: string): DateModel {
    let model = new DateModel();
    if (date) {
      if (moment(date).isValid()) {
        model.formatted = moment(date).format(format);
      }
      else {
        return null;
      }
    }

    return model;
  }
}