import { ServicoProposta } from './servico-proposta';
import { TipoContainer } from './tipo-container';
export class PropostaComercialFiltro {
    idProposta: number = 0;
    idTrecho: number = 0;
    numProposta: number = 0;
    idMunicOrigem: number = 0;
    idMunicDestino: number = 0;
    idPortolOrigem: number = 0;
    idPortoDestino: number = 0;
    tamanhoContainer: number = 0;
    idCategoriaContainer: number = 0;
    idFamiliaProduto: number = 0;
    flagOva: string = '[INDEFINIDO]';
    flagDesova: string = '[INDEFINIDO]';
    flagConferente: string = '[INDEFINIDO]';
    flagEscolta: string = '[INDEFINIDO]';
    flagShipperOwnContainer: string = '[INDEFINIDO]';
    flagAjudanteOvaDesova: string = '[INDEFINIDO]';
    flagCargaPerigosa: string = '[INDEFINIDO]';
    flagCargaOver: string = '[INDEFINIDO]';
    modal: string = '';
    tipoOperacaoComercial: string = '[INDEFINIDO]';
    tipoDeContainer: TipoContainer = new TipoContainer();
    servicosDaProposta: ServicoProposta[] = [];
    ListaTipoServicoOri: string = '';
    ListaTipoServicoDes: string = '';
}