import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import swal from 'sweetalert2';

import { Porto } from 'app/_models/porto';
import { ParametroPorto } from 'app/_models/parametro-porto';
import { BaseParametrosHoras } from 'app/admin/parametro/horas/base.parametros.horas';

import { MessageComponent } from './../../../shared/message/message.component';

import { LogService } from './../../../_services/log.service';
import { MessageService } from './../../../_services/message.service';
import { ParametroService } from './../../../_services/parametro.service';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

declare var ocultarControleMenu: any;
declare var ocultarSwalOverlay: any;
declare var removeSwal: any;

@Component({
  selector: 'app-horas',
  templateUrl: './horas.component.html',
  styleUrls: ['./horas.component.scss']
})
export class HorasComponent extends BaseParametrosHoras implements OnInit {

  edicaoExcecoes: boolean;
  portos: Porto[];
  parametrosPadrao: ParametroPorto;
  parametrosExcecoes: ParametroPorto[];
  parametroExcecaoModal: ParametroPorto;
  loading: boolean = false;
  mensagensDeErro: string[] = [];
  mensagemErroModal: string = '';

  @ViewChild('btnCloseModal', {static: true}) private btnCloseModal: ElementRef;
  @ViewChild('btnShowModal', {static: true}) private btnShowModal: ElementRef;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(private _msgService: MessageService,
    private _logService: LogService,
    private _parametroService: ParametroService) {

    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
        if (msg.mensagem instanceof Array)
          this.mensagem.mostrarMensagemArray(msg.tipo, msg.mensagem);
        else
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
    this.parametroExcecaoModal = new ParametroPorto();
    this.ObterParametros();
  }

  ObterParametros() {
    this.loading = true;
    this._parametroService.carregarPagina()
      .subscribe(pagina => {
        this.parametrosPadrao = pagina.ParametroPortoPadrao;
        this.parametrosExcecoes = pagina.ParametroPortoExcecoes;
        this.portos = pagina.Portos;
        this.loading = false;
      });
  }

  clickEditarParametro(parametro: ParametroPorto){
    this.edicaoExcecoes = true;
    var p = new ParametroPorto();
    p.NomePorto = parametro.NomePorto;
    p.PortoID = parametro.PortoID;
    p.QtdHrAgendColetaPA = parametro.QtdHrAgendColetaPA;
    p.QtdHrAgendColetaPO = parametro.QtdHrAgendColetaPO;
    p.QtdHrAgendEntrega = parametro.QtdHrAgendEntrega;
    p.QtdHrEntrega = parametro.QtdHrEntrega;
    p.QtdHrMinimasAgendColeta = parametro.QtdHrMinimasAgendColeta;
    p.QtdHrMinimasAgendEntrega = parametro.QtdHrMinimasAgendEntrega;
    this.parametroExcecaoModal = p;
    this.btnShowModal.nativeElement.click();
  }

  clickExcluirParametro(parametro: ParametroPorto){
    removeSwal();
    swal({
      title: 'Confirmação',
      text: 'Deseja excluir os parâmetros do porto \'' + parametro.NomePorto + '\'?.',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(success => {

      this.loading = true;
      this._parametroService.apagarParametrosPorto(parametro.PortoID)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this._msgService.addMessage('Definição de Horas', result.Message, "success");
            this.ObterParametros();
          } else {
            result.Erros.forEach(element => this.mensagensDeErro.push(element.Message));
            this._msgService.addMessageArray("Definição de Horas", this.mensagensDeErro, "error", LogFrontFuncionalidade.Parametros, Operacao.Exclusao, parametro);
          }
      });
      ocultarControleMenu();
      ocultarSwalOverlay();

    }, dismiss => {
      removeSwal();
    });
  }

  Salvar() {
    if (this.ValidaCampos(this.parametrosPadrao)) {
      this.loading = true;
      this._parametroService.atualizarParametrosPorto(this.parametrosPadrao)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this._msgService.addMessage('Definição de Horas', result.Message, "success");
            this.ObterParametros();
          } else {
            result.Erros.forEach(element => this.mensagensDeErro.push(element.Message));
            this._msgService.addMessageArray("Definição de Horas", this.mensagensDeErro, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.parametrosPadrao);
          }
      });
    }
    if (this.mensagensDeErro.length > 0) {
      this._msgService.addMessageArray("Definição de Horas", this.mensagensDeErro, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.parametrosPadrao);
    }
  }

  SalvarExcecao(){
    this.mensagemErroModal = '';
    if (!this.edicaoExcecoes) {
      var porto: Porto = this.portos.find(p => p.Codigo == this.parametroExcecaoModal.PortoID);
      if (!porto) {
        this.mensagemErroModal = 'Informe o Porto para qual deseja cadastrar a Exceção';
        this._logService.logFront(this.parametroExcecaoModal, LogFrontFuncionalidade.Parametros, this.mensagemErroModal, Operacao.Alteracao, "Horas");
        return;
      }
      else
        this.parametroExcecaoModal.NomePorto = porto.Descricao;
    }

    if (this.ValidaCampos(this.parametroExcecaoModal, true)) {
      this.loading = true;
      var servico = (this.edicaoExcecoes) ?
        this._parametroService.atualizarParametrosPorto.bind(this._parametroService) :
        this._parametroService.salvarParametrosPorto.bind(this._parametroService);
      servico(this.parametroExcecaoModal)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this.edicaoExcecoes = false;
            this.btnCloseModal.nativeElement.click();
            this.parametroExcecaoModal = new ParametroPorto();
            this._msgService.addMessage('Definição de Horas', result.Message, "success");
            this.ObterParametros();
          } else {
            result.Erros.forEach(element => this.mensagensDeErro.push(element.Message));
            this.mensagemErroModal = this.mensagensDeErro.join('; ');
            this._logService.logFront(this.parametroExcecaoModal, LogFrontFuncionalidade.Parametros, this.mensagemErroModal, Operacao.Alteracao, "Horas");
          }
      });
    }
    if (this.mensagensDeErro.length > 0) {
      this.mensagemErroModal = this.mensagensDeErro.join('; ');
      this._logService.logFront(this.parametroExcecaoModal, LogFrontFuncionalidade.Parametros, this.mensagemErroModal, Operacao.Alteracao, "Horas");
    }
  }

  CancelarExcecao(){
    this.mensagemErroModal = '';
    this.parametroExcecaoModal = new ParametroPorto();
    this.edicaoExcecoes = false;
  }

  ValidaCampos(parametro: ParametroPorto, permiteZero: boolean = false) {
    this.mensagensDeErro = [];
    this.ValidaParametro(parametro.QtdHrEntrega, "QtdHrEntrega", permiteZero);
    this.ValidaParametro(parametro.QtdHrAgendEntrega, "QtdHrAgendEntrega", permiteZero);
    this.ValidaParametro(parametro.QtdHrAgendColetaPA, "QtdHrAgendColetaPA", permiteZero);
    this.ValidaParametro(parametro.QtdHrAgendColetaPO, "QtdHrAgendColetaPO", permiteZero);
    this.ValidaParametro(parametro.QtdHrMinimasAgendColeta, "QtdHrMinimasAgendColeta", permiteZero);
    this.ValidaParametro(parametro.QtdHrMinimasAgendEntrega, "QtdHrMinimasAgendEntrega", permiteZero);

    let soma = this.convertNullToNumber(parametro.QtdHrEntrega) +
               this.convertNullToNumber(parametro.QtdHrAgendEntrega) +
               this.convertNullToNumber(parametro.QtdHrAgendColetaPA) +
               this.convertNullToNumber(parametro.QtdHrAgendColetaPO) +
               this.convertNullToNumber(parametro.QtdHrMinimasAgendColeta) +
               this.convertNullToNumber(parametro.QtdHrMinimasAgendEntrega);

    if (permiteZero && (soma == 0))
      this.mensagensDeErro.push("Informe ao menos o valor diferente de zero para um parâmetro.");

    return this.mensagensDeErro.length == 0;
  }

  private convertNullToNumber(valor): number {
    return (!valor) ? 0 : valor;
  }

  private ValidaParametro(parametro: number, chaveDescricao: string, permiteZero: boolean = false){
    if (parametro == undefined) {
      if (!permiteZero)
        this.MensagemErroObrigatório(chaveDescricao);
      else
        parametro = 0;
    }

    if (parametro <= 0 && !permiteZero)
      this.MensagemErroInferior0(chaveDescricao);
    if (parametro > 999)
      this.MensagemErroSuperior999(chaveDescricao);
  }

  private MensagemErroInferior0(chaveDescricao: string) {
    this.mensagensDeErro.push("Campo '" + this.descricoes[chaveDescricao] + "': a quantidade de horas deve ser superior a 0.");
  }

  private MensagemErroSuperior999(chaveDescricao: string) {
    this.mensagensDeErro.push("Campo '" + this.descricoes[chaveDescricao] + "': a quantidade de horas deve ser inferior a 1000.");
  }

  private MensagemErroObrigatório(chaveDescricao: string) {
    this.mensagensDeErro.push("Campo '" + this.descricoes[chaveDescricao] + "': informe a quantidade de horas.");
  }
}
