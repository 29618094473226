export enum AnalyticsDimensoes {
    Empresa = "Empresa",
    Classificacao = "Classificação",
    Tela = "Tela",
    Filtro = "Filtro",
    Download = "Download",
    Operacao = "Operação",
    Erro = "Erro",
    Autenticado = "Autenticado",
    Email = "E-mail"
}