import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { OrdemServico } from 'app/_models/ordemServico';
import { BaseComponent } from 'app/shared/base.component';
import { DatepickerService } from './../../../_services/datepicker.service';
import { DatePickerOptions } from './../../../shared/ng2-datepicker/ng2-datepicker.component';

@Component({
  selector: 'fechar-ordem',
  templateUrl: './fechar-ordem.component.html',
  styleUrls: ['./fechar-ordem.component.scss']
})

export class FecharOrdemComponent extends BaseComponent implements OnInit {
  mascaraHoraInicial: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  mascaraHoraFinal: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  @Input() index: number;
  @Input() exibirReplicar: boolean;
  @Input() OS: OrdemServico;

  @Output() confirmar: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() cancelar: EventEmitter<OrdemServico> = new EventEmitter();

  options: DatePickerOptions;

  constructor(public _datePickerService: DatepickerService) {
    super();
  }

  changeRegex(input_time, mascara){
    if(input_time.charAt(0) == '2'){
       mascara[1]=new RegExp('[0-3]')
    }else{
       mascara[1]=new RegExp('[0-9]')
    }
  }

  ngOnInit() {
    this.options = this._datePickerService.GenerateOptionsConfig(null);
  }

  private sim() {
    this.confirmar.emit();
  }

  private nao() {
    this.cancelar.emit();
  }

  getMunicipio(): string {
    return (this.OS.municOriDes) ? this.OS.municOriDes + ' - ' + this.OS.ufOriDes : '-';
  }
}
