
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { Terminal } from '../_models/terminal';
import { ValidationResult } from '../_models/validation-result';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TerminaisService {

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }

    obterTerminais(): Observable<Terminal[]> {
      return this.baseService.Get('terminal').pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('Parametro - Terminais', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Consulta, null);
          }

          return retorno.Data as Terminal[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }

  salvarTerminal(terminal: Terminal): Observable<ValidationResult> {
    return this.baseService.Post('terminal', terminal).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Parametro - Terminais', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Inclusao, terminal);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  atualizarTerminais(terminais: Terminal[]): Observable<ValidationResult> {
    return this.baseService.Put('terminal', terminais).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Parametro - Terminais', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Alteracao, terminais);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  excluirTerminal(terminalID: number): Observable<ValidationResult> {
    return this.baseService.Delete('terminal/' + terminalID).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Parametro - Terminais', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Exclusao, terminalID);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

}
