import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from './../../shared/shared.module';
import { ProvedorModule } from './../provedor.module';
import { DatePickerModule } from './../../shared/ng2-datepicker/ng2-datepicker.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { OrdemServicoComponent } from './ordem-servico.component';
import { IncidenteComponent } from './incidente/incidente.component';
import { OrdemServicoRoutingModule } from './ordem-servico.routing.module';
import { DatepickerService } from 'app/_services/datepicker.service';
import { MessageService } from 'app/_services/message.service';
import { GroupbyService } from 'app/_services/groupby.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DatePickerModule,
    CurrencyMaskModule,
    ProvedorModule,
    SharedModule,
    OrdemServicoRoutingModule
  ],
  declarations: [
    OrdemServicoComponent,
    IncidenteComponent
  ],
  exports: [
    ProvedorModule
  ],
  providers: [
      OrdemServicoService,
      DatepickerService,
      MessageService,
      GroupbyService
  ]
})

export class OrdemServicoModule { }
