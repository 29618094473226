import { forkJoin, Observable, throwError } from 'rxjs';
import { AppService } from './app.service';
import { AnalyticsConfig } from '../_models/analytics-config';
import { UsuarioLogado } from 'app/_models/usuariologado';
import { MapsConfig } from 'app/_models/maps-config';

import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import { map, timestamp } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { ConstantPool } from '@angular/compiler';
import { environment } from 'environments/environment';
import { ConfigService } from './config.service';
import { Timestamp } from 'rxjs-compat';


declare global {
  interface Navigator {
    msSaveBlob: (blob: any, defaultName?: string) => boolean
    msSaveOrOpenBlob: (blob: any, defaultName?: string) => boolean
  }
}
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  pathPublicos : {path : string, tipo:string} [] = [
    {
      path:"account",
      tipo:"POST"
    },
    {
      path:"account/ResetSenha",
      tipo:"POST"
    },
    {
      path:"account/CadastrarSenha",
      tipo:"POST"
    },
    {
      path:"account/email",
      tipo:"POST"
    },
    {
      path:"analytics",
      tipo:"POST"
    },
    {
      path:"anel-rota",
      tipo:"GET"
    },
    {
      path:"faleconosco/assuntos",
      tipo:"GET"
    },
    {
      path:"faleconosco/emailfalecom",
      tipo:"GET"
    },
    {
      path:"faleconosco",
      tipo:"POST"
    },
    {
      path:"log/front",
      tipo:"POST"
    },
    {
      path:"municipio",
      tipo:"GET"
    },
    {
      path:"municipio",
      tipo:"POST"
    },
    {
      path:"municipio/filtro",
      tipo:"GET"
    },
    {
      path:"navio/bykey",
      tipo:"POST"
    },
    {
      path:"ok-entrega/canhoto-links",
      tipo:"POST"
    },
    {
      path:"parametro/codigo",
      tipo:"GET"
    },
    {
      path:"parametro/portos",
      tipo:"GET"
    },
    {
      path:"parametro/porto/padrao",
      tipo:"GET"
    },
    {
      path:"parametro/portos/todos",
      tipo:"GET"
    },
    {
      path:"parametro/bykey",
      tipo:"POST"
    },
    {
      path:"parametro/saveFile",
      tipo:"POST"
    },
    {
      path:"usuario/registrar",
      tipo:"POST"
    },
    {
      path:"schedule/priorizado",
      tipo:"POST"
    },
    {
      path:"schedule/municipios",
      tipo:"GET"
    },
    {
      path:"schedule",
      tipo:"POST"
    },
    {
      path:"schedule/pdf",
      tipo:"POST"
    }
  ];

  pathUrlByArea : string;

  constructor(protected http: HttpClient,
    private _configService: ConfigService,
    protected appService: AppService) {
      this.pathUrlByArea =  this.appService.apiUrlAreaPublica;
    }


  downloadAllAndZip(urls: string[], nomesArquivos: string[], nomeZip: string) {
    this.BaseGetAllBlobforkJoin(urls).subscribe((x : Blob[]) => {
      this.zipAndDownload(x?.filter(x => x?.type == 'application/x-download'), nomesArquivos, nomeZip);
    });
  }

  BaseGetAllBlobforkJoin(urls: string[]) : Observable<Blob[]> {
    const requests = [];
    urls.forEach(x => {
      requests.push(this.http.get(x, { responseType: 'blob' }));
    })
    return forkJoin<Blob>([...requests]);
  }

  OpenNewTab(url: string) {
    window.open(url, "_blank");
  }

  public get UsuarioLogado(): UsuarioLogado {
    return this.appService.UsuarioLogado;
  }

  public get ConfigAnalytics(): AnalyticsConfig {
    return this.appService.configAnalytics;
  }

  public get ConfigMaps(): MapsConfig {
    return this.appService.configMaps;
  }

  public handleError(error: any) {
    if (error.status === 401) {
      this.appService.acessoNegado();

      //return observableThrowError('Unauthorized');
      return throwError('Unauthorized');
    }

    let erro = error && error.error_description;
    //return observableThrowError(erro);
    return throwError(erro);
  }

  private getOptionsArgs(): any {
    // let headers = new Headers();
    // if (this.appService.UsuarioLogado != null)
    //   headers = new Headers({ 'Authorization': 'Bearer ' + this.appService.UsuarioLogado.Token });
    // headers.append('Content-Type', 'application/json');
    //return new RequestOptions({ headers: headers });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Consumer-Key": this.appService.configInfo.forgeRockAuth.costumer_key
    });

    var tokenForge = localStorage.getItem(`al.${this._configService._config.forgeRockAuth.urlAuth}.alianca001.id_token`);

    if (tokenForge)
      headers = new HttpHeaders({
        'Authorization': 'Bearer ' + tokenForge,
        "Consumer-Key": this.appService.configInfo.forgeRockAuth.costumer_key,
        'Content-Type': 'application/json',
        "Internal-Token" : this.UsuarioLogado != null ? this.UsuarioLogado.TokenInterno : "teste"
      });

    let options = {
      headers: headers
    };
    return options;
  }

  public Post(path: string, data: any): Observable<any> {
    this.TrataRequest(path, "POST")
    return this.http.post(this.pathUrlByArea + path, JSON.stringify(data), this.getOptionsArgs());
  }

  public Put(path: string, data: any): Observable<any> {
    this.TrataRequest(path, "PUT")
    return this.http.put(this.pathUrlByArea + path, JSON.stringify(data), this.getOptionsArgs());
  }

  public Delete(path: string): Observable<any> {
    this.TrataRequest(path, "DELETE")
    return this.http.delete(this.pathUrlByArea + path, this.getOptionsArgs());
  }

  public Get(path: string): Observable<any> {
    this.TrataRequest(path, "GET")
    return this.http.get(this.pathUrlByArea + path, this.getOptionsArgs());
  }

  private TrataRequest(path,  tipo:string){
    var areaPublica = this.VerificadorArea(path, tipo);

    this.pathUrlByArea = areaPublica ? this.appService.apiUrlAreaPublica : this.appService.apiUrl;

     this.ConfereSessaoUsuario();
  }

  private VerificadorArea(path:string, tipo:string){

    path = path.split('?')[0];

    if(this.appService.isAuthenticated() == true)
      return false;

    let publico = this.pathPublicos.filter(p => p.path == path && p.tipo == tipo).length > 0;

    if(publico == false){
      console.log("Path", path)
      console.log("Request", tipo)
      console.log("Publico", publico)
    }

    return publico;
  }

  public ConfereSessaoUsuario(){

    var user =  localStorage.getItem('currentUser');

    if(user == null) return;

    var dataExpiracaoSessao = localStorage.getItem("dataExpiracaoSessao");

    if(!dataExpiracaoSessao){
      this.SessaoExpirada();
    }

    var timeStamp = parseInt(dataExpiracaoSessao);

    if(timeStamp){
      var dataExpiracao = new Date(timeStamp);
      var dataAtual = new Date();

      if(dataAtual > dataExpiracao){
        this.SessaoExpirada();
      }
    }
  }

  private SessaoExpirada(){
    localStorage.removeItem("dataExpiracaoSessao")
    this.appService.acessoNegado();
  }

  public DownloadXLSX(data: any[], titulos: any[], filename: string) {
    const xlsx = this.ConvertXLSX(data, titulos, filename)
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([xlsx], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);

    // if (window.navigator && window.navigator.appVersion && window.navigator.appVersion.indexOf('.NET') > 0 && typeof window.navigator.msSaveBlob === 'function') {
    //   window.navigator.msSaveBlob(blob, filename);
    // }
    if (window.navigator && typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      a.href = url;
      a.download = filename;
      a.click();
    }
  }

  jsonToXlsx(data) {
    var defaults = {
      start: {
        cell: 0,
        row: 0
      }
    };
    var options = defaults;

    var ws_name = "sheetname";

    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.Props = {};
    wb.SheetNames = [];

    var ws = {};

    var range = { s: { c: options.start.cell, r: options.start.row }, e: { c: 0, r: 0 } };

    var len = [];

    for (var R = 0; R != data.length; ++R) {
      if (range.e.r < R) range.e.r = R;
      for (var C = 0; C != data[R].length; ++C) {
        if (range.e.c < C) range.e.c = C;
        var cell = { v: data[R][C], t: '', z: '' };
        if (cell.v == null) continue;
        if (!len[C] || `${data[R][C]}`.length > len[C]) {
          len[C] = `${data[R][C]}`.length;
        }

        var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
        cell.t = 's';
        cell.z = '@';
        ws[cell_ref] = cell;

        XLSX.utils.format_cell(ws[cell_ref]);
      }
    }
    ws['!ref'] = XLSX.utils.encode_range(range);

    var wscols = [];
    len.forEach(tam => {
      wscols.push({ wch: tam * 1.5 > 100 ? 100 : tam * 1.3 });
    });
    ws['!cols'] = wscols;

    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    return wb;
  }

  ConvertXLSX(data: any[], titulos: any[], filename: string) {
    const wb = this.jsonToXlsx([titulos, ...data]);

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    var buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    return buf;
  }

  // Final Code for Download CSV Function
  public DownloadCSV(data: any[], titulos: any[], filename: string, cabecalho: any[] = []) {
    var csvData = this.ConvertToCSV(data, titulos, cabecalho);
    var blob = new Blob([csvData], { type: 'text/csv' });
    this.download(blob, filename);
  }

  public download(blob: Blob, filename: string) {
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    var url = window.URL.createObjectURL(blob);
    if (window.navigator && window.navigator.appVersion && window.navigator.appVersion.indexOf('.NET') > 0 && typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      a.href = url;
      a.download = filename;
      a.click();
    }
  }

  public downloadFileByUrl(url: string, filename: string) {
    return this.http.get(url,
      { responseType: 'blob', headers: null }
    ).pipe(
      map(x => {
        if (x?.type != 'application/x-download')
          throw new Error("Não foi possível baixar o arquivo");
        this.download(x, filename);
      }));
  }

  zip(data: Blob[], namesArchive: string[]) {
    let zip = new JSZip();
    data?.forEach((file, index) => {
      zip.file(namesArchive[index], file)
    })
    return zip.generateAsync({ type: "blob" });
  }

  public zipAndDownload(data: Blob[], namesArchive: string[], name: string) {
    let zip = new JSZip();
    data.forEach((file, index) => {
      zip.file(namesArchive[index], file)
    })

    zip.generateAsync({ type: "blob" })
      .then(content => {
        this.download(content, name);
      });
  }

  addFileToZipAndDownload(data: Blob, dataFile: any, nameFIle: string) {
    let zip = new JSZip();
    zip.loadAsync(data).then(res => {
      res.file(nameFIle, dataFile);
      res.generateAsync({ type: "blob" })
        .then(content => {
          this.download(content, "download.zip");
        });
    })
  }

  ConvertToCSV(array: any[], titulos: any[], cabecalho: any[] = []) {
    var str = "\"sep=;\"\r\n";

    if ((cabecalho != undefined) && (cabecalho != null) && (cabecalho.length != 0)) {
      for (var i = 0; i < cabecalho.length; i++) {
        str += cabecalho[i] + '\r\n';
      }
    }

    if ((titulos != undefined) && (titulos != null)) {
      var line = '';
      for (var i = 0; i < titulos.length; i++) {
        if (line != '') line += ';'

        line += titulos[i];
      }
      str += line + '\r\n';
    }

    for (var i = 0; i < array.length; i++) {
      var line = '';

      for (var index in array[i]) {
        if (line != '') line += ';'
        var str2 = array[i][index] ? array[i][index].toString().replace(new RegExp('"', 'g'), '""') : "";
        line += '"' + str2 + '"';
      }
      str += line + '\r\n';
    }

    str = str.replace(new RegExp("null", 'g'), "");
    str = str.replace(new RegExp("undefined", 'g'), "");

    var uint8 = new Uint8Array(str.length);
    for (var i = 0; i < uint8.length; i++) {
      uint8[i] = str.charCodeAt(i);
    }

    return uint8;
  }

  public setUsuarioLogado(usuario: UsuarioLogado) {
    this.appService.UsuarioLogado = usuario;
    localStorage.setItem('currentUser', JSON.stringify(this.appService.UsuarioLogado));
  }

  public limpaStorage(): void {
    this.appService.limpaStorage();
  }

  public getVersaoWebPortal() {
    return this.appService.getVersaoWebPortal();
  }

  public getBrowserVersao() {
    return this.appService.getBrowserVersao();
  }
}
