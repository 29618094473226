import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TrackingLista } from "app/_models/tracking-lista";
import { TipoEventoTracking } from "app/_models/tipoEventoTracking";

import { SharedService } from './../../../_services/shared.service';
import { RastreamentoCargasVM } from "app/_services/rastreamento-cargas-vm";

import { BaseComponent } from './../../../shared/base.component';
import { PaginaParametroPorto } from 'app/_models/pagina-parametro-porto';
import { ParametroPorto } from 'app/_models/parametro-porto';

@Component({
  selector: 'filtro-rastreamento',
  templateUrl: './filtro-rastreamento.component.html',
  styleUrls: ['./filtro-rastreamento.component.scss']
})
export class FiltroRastreamentoComponent extends BaseComponent implements OnInit {

  constructor(private _sharedService: SharedService) {
    super();
   }
  ngOnInit() { }

  quantidadeConfirmado: number = 0;
  quantidadeColetada: number = 0;
  quantidadeDepositada: number = 0;
  quantidadeNavegando: number = 0;
  quantidadeDesembarcada: number = 0;
  quantidadeEmEntrega: number = 0;
  quantidadeRealizada: number = 0;
  quantidadeRetiradadocheio: number = 0;
  quantidadeDevolucaoVazio: number = 0;
  qtdPendenteAgendNavegando: number = 0;
  qtdPendenteAgendDesembarcada: number = 0;
  paginaParametroPorto: PaginaParametroPorto;

  @Output() stepAlterado: EventEmitter<string> = new EventEmitter<string>();

  stepAtivo: string = 'total';

  get quantidadeTotal(): number {
    return this.quantidadeConfirmado + this.quantidadeColetada + this.quantidadeDepositada +
           this.quantidadeNavegando + this.quantidadeDesembarcada +
           this.quantidadeEmEntrega + this.quantidadeRealizada + this.quantidadeRetiradadocheio +
           this.quantidadeDevolucaoVazio;
  }

  formataQuantidade(quantidade: number): string {
    if ((quantidade == undefined) || (quantidade == 0)) {
      return '0';
    }

    const quantidadeFormatada = (quantidade < 10 ? ('0' + quantidade).slice(-2) : quantidade.toString());
    return quantidadeFormatada;
  }

  styleLinkStep(quantidade: number){
    return (quantidade > 0) ? 'auto' : 'none';
  }

  possuiItens(quantidade: number){
    return (quantidade > 0) ? ' possui-itens' : '';
  }

  stepEstaAtivo(step: string): string{
    return (step == this.stepAtivo) ? 'active' : '';
  }

  stepClick(step: string){
    this.stepAtivo = step;
    this.stepAlterado.emit(step);
  }

  private atribuiQuantidadePorTipoEvento(tiposEventos: TipoEventoTracking[]): void{
    //console.log(tiposEventos);
    if (!tiposEventos) {
      this.zeraQuantidades();
      return;
    }

    this.quantidadeConfirmado = 0;
    this.quantidadeColetada = 0;
    this.quantidadeDepositada = 0;
    this.quantidadeNavegando = 0;
    this.quantidadeDesembarcada = 0;
    this.quantidadeEmEntrega = 0;
    this.quantidadeRealizada = 0;
    this.quantidadeRetiradadocheio = 0;
    this.quantidadeDevolucaoVazio = 0;

    tiposEventos.forEach(x => {
      if (x.Descricao){
        let descricao: string = x.Descricao.toLowerCase().trim();

        if (descricao.startsWith('coletada')) {
          this.quantidadeColetada = x.Quantidade;
        } else if (descricao.startsWith('booking confirmado')) {
          this.quantidadeConfirmado = x.Quantidade;
        }else if (descricao.startsWith('depositada')) {
          this.quantidadeDepositada = x.Quantidade;
        } else if (descricao.startsWith('navegando')) {
          this.quantidadeNavegando = x.Quantidade;
        } else if (descricao.startsWith('desembarcada')) {
          this.quantidadeDesembarcada = x.Quantidade;
        } else if (descricao.startsWith('em')) {  // Em entrega
          this.quantidadeEmEntrega = x.Quantidade;
        } else if (descricao.startsWith('realizada')) {
          this.quantidadeRealizada = x.Quantidade;
        } else if(descricao.startsWith('retirada')) {
          this.quantidadeRetiradadocheio = x.Quantidade;
        } else if(descricao.startsWith('retira')) {
          this.quantidadeRetiradadocheio = x.Quantidade;
        } else if(descricao.startsWith('devolução')) {
          this.quantidadeDevolucaoVazio = x.Quantidade;
        }
      }
    });
  }

  private atribuiQuantidadeDeEntregaPendente(trackings: TrackingLista[]): void {
    if (!trackings) {
      this.qtdPendenteAgendDesembarcada = 0;
      this.qtdPendenteAgendNavegando = 0;
      return;
    }

    if (trackings) {
      this.qtdPendenteAgendNavegando = trackings.filter(x => x.PendenteAgendamentoNavegando).length;
      this.qtdPendenteAgendDesembarcada = trackings.filter(x => x.PendenteAgendamentoDesembarcada).length;

      /*
      this.qtdPendenteAgendNavegando = trackings.filter(x =>
        !x.idAgendEntrega &&
        x.statusBooking &&
        x.statusBooking.toLowerCase().startsWith('navegando') &&
        x.modal && x.modal.endsWith('PA') &&
        (this._sharedService.addHours(this._sharedService.corrigeData(x.prevEntrega), -this.obterQtdHorasAgendaEntrega(x)) <= new Date())
      ).length;

      this.qtdPendenteAgendDesembarcada = trackings.filter(x =>
        !x.idAgendEntrega &&
        x.statusBooking &&
        x.statusBooking.toLowerCase().startsWith('desembarcada') &&
        x.modal && x.modal.endsWith('PA') &&
        (this._sharedService.addHours(this._sharedService.corrigeData(x.prevEntrega), -this.obterQtdHorasAgendaEntrega(x)) <= new Date())
      ).length;
*/
      if (!this.qtdPendenteAgendNavegando) {
        this.qtdPendenteAgendNavegando = 0;
      }

      if (!this.qtdPendenteAgendDesembarcada) {
        this.qtdPendenteAgendDesembarcada = 0;
      }
    }
  }

  carregaDadosEmComponente(rastreamentosCargasVM: RastreamentoCargasVM): void {
    this.atribuiQuantidadePorTipoEvento(rastreamentosCargasVM.tiposEventos);
    this.atribuiQuantidadeDeEntregaPendente(rastreamentosCargasVM.trackings);
  }

  obterQtdHorasAgendaEntrega(tracking: TrackingLista): number{
      var idPorto = tracking.idPortoDestino;
      var parametroExcecao = this.paginaParametroPorto.ParametroPortoExcecoes.find(p => p.PortoID == idPorto);
      var parametro =  (parametroExcecao && parametroExcecao.QtdHrEntrega != 0) ? parametroExcecao : this.paginaParametroPorto.ParametroPortoPadrao;
      return (parametro && parametro.QtdHrEntrega) ? parametro.QtdHrEntrega : 0; // .QtdHrMinimasAgendEntrega
  }

  zeraQuantidades() {
    this.quantidadeConfirmado = 0;
    this.quantidadeColetada = 0;
    this.quantidadeDepositada = 0;
    this.quantidadeNavegando = 0;
    this.quantidadeDesembarcada = 0;
    this.quantidadeEmEntrega = 0;
    this.quantidadeRealizada = 0;
    this.qtdPendenteAgendDesembarcada = 0;
    this.qtdPendenteAgendNavegando = 0;
    this.quantidadeDevolucaoVazio = 0;
    this.quantidadeRetiradadocheio = 0;
  }
}
