export enum AnalyticsDownload {
    ExportacaoCSV = "Exportação CSV",
    Manual = "Manual",
    BoletoFatura = "Boleto/Fatura",
    Canhoto = "Canhoto",
    CTeXML = "XML CTe",
    CTePDF = "PDF CTe",
    RPS = "RPS",
    Zip = "Zip",
    ProgramacaoCompleta = "Programação de Navios Completa"
}