import { Component, Input, ElementRef, AfterContentInit} from '@angular/core';
declare var jQuery: any;

@Component({
    selector: 'slick-slider',
    template: `
        <ng-content></ng-content>
    `
})
export class SlickSliderComponent implements AfterContentInit{
    @Input() options: any;

    $element: any;

    defaultOptions: any = { dots: false,slidesToShow: 2, slidesToScroll: 1,infinite:false };

    constructor(private el: ElementRef) {
    }

    ngAfterContentInit() {
        for (var key in this.options) {
            this.defaultOptions[key] = this.options[key];
        }

        this.load();
    }

    load(){
        this.$element = jQuery(this.el.nativeElement).slick(this.defaultOptions);
    }

    reload() {
        jQuery(this.el.nativeElement).slick('unslick');
        this.load();
    }
}