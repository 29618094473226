import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";

export class UsuarioPesquisa
{
    CadastradosDe: DateModel;
    CadastradosAte: DateModel;
    Email: string = '';
    Nome: string = '';
    RazaoSocial: string = '';
    Perfil: string = ''; //string com IDs dos perfils selecionados separados por ";"
    Status: string = ''; //string com IDs dos status selecionados separados por ";"
    CNPJ: string = '';   //string com CNPJ ou parte do CNPJ a ser pesquisado no CNPJ principal ou nos associados
}
