import { Perfil } from './perfil';
import { UsuarioCNPJ } from './usuario-cnpj';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { StatusUsuario } from 'app/_enums/status-usuario.enum';

export class Usuario{
    UsuarioID: number;
    Email: string;
    Nome: string;
    RazaoSocial: string;
    NomeFantasia: string;
    CNPJ: string;
    InscricaoEstadual: string;
    Logradouro: string;
    Numero: string;
    Complemento: string;
    Bairro: string;
    Municipio: string;
    UF: string;
    CEP: string;
    Telefone: string;
    PerfilUsuario: PerfilUsuario;
    Vendedor: string;
    EscritorioResponsavel: string;
    Status: StatusUsuario;
    MotivoRecusa: string;
    DataCadastro: Date;
    DataAprovacao: Date;
    DataReprovacao: Date;

    PerfilID: number;
    Perfil: Perfil;
    CNPJs: UsuarioCNPJ[];
    UsuarioIDeCargo: string;
    strDataCadastro: string;
    strDataAprovacao: string;
    strDataReprovacao: string;
    ListaCNPJs: string;
}
